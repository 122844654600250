import { lazy } from 'react';

import PagesLayout from 'Layout/Pages';

// project import
import Loadable from 'components/Loadable';
import DashboardLayout from 'Layout/Dashboard';
import SubscriptionLayout from '@/Layout/Subscription';
import UserProfileLayout from '@/Layout/UserProfile';

const MaintenanceError500 = Loadable(lazy(() => import('pages/maintenance/500')));

// render - sample page
const DashboardPage = Loadable(lazy(() => import('pages/undress/dashboard-page')));
const RegisterPage = Loadable(lazy(() => import('pages/auth/register')));
const SubscriptionPage = Loadable(lazy(() => import('pages/subscription/subscription-page')));
const UserProfilePage = Loadable(lazy(() => import('pages/userprofile/userprofile-page')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  children: [
    {
      path: '',
      element: <DashboardLayout />,
      children: [
        {
          path: '/dashboard',
          element: <DashboardPage />
        },
      ]
    },
    {
      path: '',
      element: <SubscriptionLayout />,
      children: [
        {
          path: '/subscription',
          element: <SubscriptionPage />
        }
      ]
    },
    {
      path: '',
      element: <UserProfileLayout />,
      children: [
        {
          path: '/profile',
          element: <UserProfilePage />
        }
      ]
    },
    {
      path: 'register',
      element: <RegisterPage />
    },
    {
      path: '/maintenance',
      element: <PagesLayout />,
      children: [
        {
          path: '500',
          element: <MaintenanceError500 />
        }
      ]
    },
  ]
};

export default MainRoutes;
